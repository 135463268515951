.input-container{
    margin-top: 20px;
    width: 100%;
    position: relative;

}
.input-container .svg-inline--fa{
    position: absolute;
    right: 20px;
    top: 20px;
}

.button-container{
    display: flex;
    margin-top: 20px;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.button-container button{
    width: 100%;
    height: 45px;
    background-color: #343434;
    border: none
}

.button-container button:hover{
    background-color: #343434;
    border: none
}

.create-password p{
    font-weight: 300;
    color: #979797;
    font-size: 17px;
}

