.barContainer{
    display: block;
    background-color: white;
    padding-inline: 20px;
    padding-block: 30px;
    height: 100vh
}
.sidebar-active{
    display: flex;
    background-color: #F0FBFB;
    margin-top: 25px;
    height: 40px;
    width: 40px;
    padding: 10px;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
}
.sidebar-pending{
    display: flex;
    margin-top: 25px;
    height: 40px;
    width: 40px;
    padding: 10px;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
}

.side-bar{
    position: fixed;
    padding-top: 40px;
}

.sidebar-active{

}