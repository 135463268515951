input {
    padding: 12px;
    width: 100%;
    border-radius: 5px;
    border: 1px solid #EFEFEF;
    align-items: center;
}

input :hover {
    border: 1px solid #EFEFEF;
}

.errorMessage {
    color: red;
    font-size: small;
}

.add-button {
    background-color: #2BC8CB !important;
    border: none !important;
}

.add-button:hover {
    background-color: #2BC8CB !important;
}

.select {
    width: 95%;
    padding: 15px;
    border-radius: 5px;
    border: 1px solid #EFEFEF;
    align-items: center;
}


/* 
.add-emp-form input{
    height: 56px;
} */

.add-cmp-form input:focus-visible {
    outline: 2px solid #2BC8CB;
    border-radius: 3px;
}

.add-cmp-form select:focus-visible {
    height: 58px;
    border: 2px solid #2BC8CB;
}

.add-cmp-form .css-t3ipsp-control {
    height: 58px;
    border: 2px solid #2BC8CB !important;
    box-shadow: none !important;
}

.add-cmp-form .css-t3ipsp-control:hover {
    height: 58px;
    border: 2px solid #2BC8CB !important;
}

.add-cmp-form .css-13cymwt-control {
    height: 58px;
    border-color: #EFEFEF;
}

.add-cmp-form .css-qbdosj-Input {
    height: 42px;
    margin: 0px !important;
}

.add-cmp-form .css-1u9des2-indicatorSeparator {
    display: none;
}

input[type="date" i] {
    color: rgb(133, 133, 133);
}

.upload-icon {
    width: 24px;
    height: 24px;
}

.upload-profile {
    width: 120px;
    height: 120px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.upload-profile input {
    width: 120px;
    height: 120px;
    opacity: 0;
    position: absolute;
    left: 0px;
    top: 0px;
}

.upload-profile {
    border: 1px dashed #2BC8CB;
    border-radius: 12px;
    background-color: #F0FBFB;
}

.upload-sec {
    position: relative;
}

.upload-profile .uploaded-img {
    object-fit: cover;
    height: 120px;
}

.upload-profile-img {
    position: absolute;
    display: flex;
    /* justify-content: center; */
    flex-direction: column;
    align-items: center;
    /* z-index: 99; */
}

.uploaded-img-set {
    z-index: 9999;
}



.date-picker form {
    height: 100%;
}

.date-picker input {
    height: 100%;
}

.upload-profile {
    border: 1px dashed #2BC8CB;
    border-radius: 12px;
    background-color: #F0FBFB;
}

.add-company-input-flied .form-floating>.form-control:not(:placeholder-shown)~label::after {
    background-color: transparent !important;
}

.form-floating {
    height: 80px;
}

.add-company-input-flied .svg-inline--fa {
    position: absolute;
    right: 20px;
    bottom: 42px;
}

.font-style{
    position: absolute;
    right: 90%;
    top: 10px;
    color: red;
}



@media only screen and (max-width: 992px) {
    input {
        width: 100%;
    }

    .button-container button {
        width: 100%;
    }
}