.companyProfile-component{
    color: #2BC8CB;
    font-weight: 600;
    font-style:normal;
}

.employee-label{
    font-size: 14px;
    font-weight: 400;
    color: #959499;
}
.cover-photo .profile-img{
    width: 134px;
    height: 120px;
    display: flex;
    align-items: center;
    border: solid black 1px;
    border-radius: 8px;
}
.cover-photo .profile-img img{
    width: 100%;
    object-fit: cover;
    height: 100%;
    border-radius: 8px;
}

.remove-button{
    background-color: #F6F6F6;
    border: 1px solid #979797;
    color: #343434;
}
.remove-button:hover{
    background-color: #F6F6F6;
    border: 1px solid #979797;
    color: #343434;
}
.edit-button{
    background-color: #343434 !important;
    border: none !important;
    color: white !important;
    height: 40px;
    display: flex;
    align-items: center;
}

.edit-button img{
    height: 20px;
    width: 20px;
    margin-bottom: 1px;
    margin-right: 4px;
}
.cover-photo{
    background-image: url("../../../../Assets/Images/cove_photo.png");
    height: 190px;
    width: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 20px;
    position: relative;
}

.profile-header-contain{
    display: flex;
    position: absolute;
    bottom: -50px;
    width: 100%;
    align-items: flex-end;
    padding-left: 20px;
}

.profile-employee-info{
    padding-left: 20px;
    margin-top: 100px;
}
.profile-employee-doc{
    padding-left: 20px;
    margin-top: 20px;
}

.profile-employee-doc h4{
    margin-bottom: 10px;
}
.profile-doc{
    width: 120px;
    height: 120px;
    background-color: #979797;
    border-radius: 8px;
    margin-right: 10px;
    background-size: cover;
    background-position: center;
}
.profile-button{
    display: flex;
    justify-content: space-between;
}
