.navigation{
    background: white;
}

.navigation .navbar{
    padding: 8px 30px;
    display: flex;
    justify-content: space-between;
    position: fixed;
    width: 100%;
    background: #fff;
    z-index: 999;
}
.title{
    display: flex;
    align-items: center;
    margin-right: 10px;
}
.admin-profile-dropdown .profile{
    width: 36px;
    height: 36px;
    padding: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.admin-profile-dropdown .profile img{
    object-fit: cover;
    max-width: 100%;
    border-radius: 50%;
}


.navigation-icon a{
    margin-right: 20px;
}

.navigation-icon a img{
    width: 32px;
}

.admin-profile-dropdown .dropdown-menu{
    position: absolute !important;
    inset: 50px auto auto -120px !important;
    border: none;
    background-color: #ffffff;
    box-shadow: 0px 0px 15px rgb(213, 210, 210);
}

.admin-profile-dropdown .dropdown-toggle::after{
    display: none !important;
}
