.header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.add-company {
    background-color: #2BC8CB !important;
    border: none !important;
    align-items: center;
    justify-content: center;
    height: 40px;
}

.add-company:hover {
    background-color: #2BC8CB;
}

.cmp-grid{
    cursor: pointer;
}

.cmp-detail-card .dropdown-toggle::after{
    display: none !important;
}

.cmp-detail-card button {
    border:none !important;
    padding: 0px;
}

.cmp-detail-card .dropdown-menu{
    position: absolute !important;
    inset: 0px auto auto -150px !important;
    border: none;
    background-color: #ffffff;
    box-shadow: 0px 0px 15px rgb(213, 210, 210);
}

.cmp-detail-card  .btn.show{
    background-color: #ffffff !important;
    color: #343434 !important;
    border: 1px solid #ffffff !important;
    box-shadow: none !important;
}

.cmp-detail-card .btn:focus-visible{
    box-shadow:none !important;
}

.add-company img{
    margin-bottom: 2px; 
}
.company-detail-box{
    display: flex;
    padding-block: 8px;
    align-items: center;
}

.cmp-img img{
    height: 56px;
    width: 56px;
    border-radius: 8px;
}

.cmp-status-active-color{
    color: green;
    font-weight: 500;
}

.cmp-status-inactive-color{
    color: red;
    font-weight: 500;
}




